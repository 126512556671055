import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "./types";

export const getPropInSelectedLanguage = <T extends object>(object: T, prop: keyof T, selectedLanguage: SUPPORTED_LANGUAGES = DEFAULT_LANGUAGE, isRequired = false) => {
  if (selectedLanguage === DEFAULT_LANGUAGE) {
    return object[prop];
  }
  const translatedValue = object[`${String(prop)}_${selectedLanguage}`];

  return isRequired ? translatedValue || object[prop] : translatedValue;
}
