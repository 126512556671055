import { CartItem, Product } from '@pedix-workspace/utils';
import { StockInfo } from './stock-info.model';
import {
  optionToStockItem,
  presentationToStockItem,
  productToStockItem,
  unkownStockItem,
} from './utils';

export type StockInfoMap = Record<string, StockInfo>;
export type StockProduct = Pick<
  Product,
  'id' | 'name' | 'stockQty' | 'hidden' | 'presentations' | 'options' | 'linkedOptionIds'
> & { active?: boolean };

export class StockMap {
  private stockInfoMap: Record<string, StockInfo>;

  constructor(
    private products: StockProduct[],
    private cartItems: CartItem[],
  ) {
    this.stockInfoMap = this.processStockInfoMap(this.products, this.cartItems);
  }

  getStockInfoByItem(itemId: string): StockInfo {
    return this.stockInfoMap[itemId] || new StockInfo(unkownStockItem(itemId));
  }

  getItemsWithUnknownStockInfo(): StockInfo[] {
    return Object.values(this.stockInfoMap).filter(stockInfo => stockInfo.status === 'unknown');
  }

  getStockInfoForCartItems(): StockInfo[] {
    return Object.values(this.stockInfoMap).filter(stockInfo => stockInfo.totalStockInCart > 0);
  }

  getStockInfoArrayByCartItem(cartItemId: string): StockInfo[] {
    return this.getStockInfoForCartItems().filter(
      stockInfo => stockInfo.getConsumedStock(cartItemId) > 0,
    );
  }

  getItemsInCartWithStockErrors(): StockInfo[] {
    return this.getStockInfoForCartItems().filter(stockInfo => stockInfo.hasMissingStock);
  }

  private processStockInfoMap(products: StockProduct[], cartItems: CartItem[]) {
    const stockInfoMap: StockInfoMap = {};

    products.forEach(product => {
      stockInfoMap[product.id] = new StockInfo(productToStockItem(product));

      if (product.presentations) {
        product.presentations.items?.forEach(presentation => {
          stockInfoMap[presentation.id] = new StockInfo(
            presentationToStockItem(presentation, product),
          );
        });
      }

      if (product.options) {
        product.options.forEach(option => {
          option.items?.forEach(item => {
            stockInfoMap[item.id] = new StockInfo(optionToStockItem(item, product));
          });
        });
      }
    });

    cartItems.forEach(cartItem => {
      if (cartItem.selectedPresentation) {
        cartItem.selectedPresentation.items.forEach(presentation => {
          if (stockInfoMap[presentation.id]) {
            const consumedStock = presentation.quantity || cartItem.qty;

            stockInfoMap[presentation.id].setConsumedStock(consumedStock, cartItem.id);
          } else {
            stockInfoMap[presentation.id] = new StockInfo(
              presentationToStockItem(presentation, cartItem.product),
              'unknown',
            );
          }
        });
      } else {
        if (stockInfoMap[cartItem.product.id]) {
          stockInfoMap[cartItem.product.id].setConsumedStock(cartItem.qty, cartItem.id);
        } else {
          stockInfoMap[cartItem.product.id] = new StockInfo(
            productToStockItem(cartItem.product),
            'unknown',
          );
        }
      }
      if (cartItem.selectedOptions) {
        cartItem.selectedOptions.forEach(selectedOption => {
          selectedOption.items.forEach(item => {
            if (stockInfoMap[item.id]) {
              stockInfoMap[item.id].setConsumedStock(
                (item.quantity || 1) * cartItem.qty,
                cartItem.id,
              );
            } else {
              stockInfoMap[item.id] = new StockInfo(
                optionToStockItem(item, cartItem.product),
                'unknown',
              );
            }
          });
        });
      }
    });

    return stockInfoMap;
  }
}
