import {
  firestoreDateParser,
  Product,
  OptionConfiguration,
  ProductOptionItem,
  ProductOptionRangeType,
  SUPPORTED_LANGUAGES,
  getPropInSelectedLanguage,
  Feature,
} from '@pedix-workspace/utils';
import { FirestoreNestedConverter, withNestedConverter } from './utils';

export type ProductConverterParams = {
  nestedConverter?: FirestoreNestedConverter<Product>;
  language?: SUPPORTED_LANGUAGES;
  features?: Feature[];
};

export const getProductConverter = ({
  language,
  features,
  nestedConverter,
}: ProductConverterParams = {}) => {
  const featuresSet = features ? new Set(features) : undefined;

  return withNestedConverter<Product>({
    toFirestore(productData: Product) {
      return productData;
    },
    fromFirestore(snapshot) {
      return convertProductData(snapshot.id, <Product>snapshot.data(), language, featuresSet);
    },
  })(nestedConverter);
};

export const convertProductData = (
  productId: string,
  productData: Partial<Product>,
  language: SUPPORTED_LANGUAGES = 'es',
  features?: Set<Feature>,
): Product => {
  // Maps old single-image to images array
  const images = getProductImagesAndCleanOldData(productData.images, productData['image']);

  // Maps old presentations array format
  const presentations = getPresentations(productId, productData.presentations);

  // Maps legacy withoutStock property
  const stockQty =
    productData.stockQty === undefined
      ? productData['withoutStock'] === true
        ? 0
        : null
      : productData.stockQty;

  const name = getPropInSelectedLanguage(productData, 'name', language, true);
  const description = getPropInSelectedLanguage(productData, 'description', language, true);
  const linkedProductTagIds = productData.linkedProductTagIds || [];
  const primaryTagId = productData.primaryTagId || undefined;

  const convertedData = {
    ...productData,
    id: productId,
    name,
    linkedProductTagIds,
    primaryTagId,
    description,
    stockQty,
    images,
    presentations,
    sortOrder: productData.sortOrder || 0,
    hidden: productData.hidden || false,
    options: getProductOptions(productId, productData.options),
    added: firestoreDateParser(productData.added),
    updated: firestoreDateParser(productData.updated),
  };

  // Removes legacy props
  delete convertedData['image'];
  delete convertedData['renditions'];
  delete convertedData['withoutStock'];

  // If provided with available features, performs data validation/truncation
  if (features && features.size > 0) {
    if (!features.has('PRODUCT_PRESENTATIONS')) {
      convertedData.presentations = null;
    }
    if (!features.has('PRODUCT_OPTIONS')) {
      convertedData.options = [];
    }
    if (!features.has('PRODUCT_GROUPS')) {
      convertedData.groupName = undefined;
    }
    if (!features.has('STOCK_CONTROL')) {
      if (convertedData.stockQty !== null && convertedData.stockQty > 0) {
        convertedData.stockQty = null;
      }
      if (convertedData.presentations) {
        convertedData.presentations.items?.forEach(presentation => {
          if (presentation.stockQty !== null && presentation.stockQty > 0) {
            presentation.stockQty = null;
          }
        });
      }
    }
    if (!features.has('PRODUCT_TAGS')) {
      convertedData.primaryTagId = null;
      convertedData.linkedProductTagIds = [];
    }
    if (convertedData.images) {
      const maxImages = getMaxGalleryImages(convertedData.isOptionItem, features);

      convertedData.images = convertedData.images.slice(0, maxImages);
    }
  }

  return <Product>convertedData;
};

export function getMaxGalleryImages(
  isOptionItem: boolean,
  features: Set<Feature> | Array<Feature>,
): number {
  if (isOptionItem) {
    if (
      Array.isArray(features)
        ? features.includes('PRODUCT_ITEM_IMAGES')
        : features.has('PRODUCT_ITEM_IMAGES')
    ) {
      return 1;
    } else {
      return 0;
    }
  } else {
    if (
      Array.isArray(features)
        ? features.includes('IMAGE_GALLERY_EXTENDED')
        : features.has('IMAGE_GALLERY_EXTENDED')
    ) {
      return 10;
    } else if (
      Array.isArray(features) ? features.includes('IMAGE_GALLERY') : features.has('IMAGE_GALLERY')
    ) {
      return 3;
    } else {
      return 1;
    }
  }
}

export const getProductImagesAndCleanOldData = (
  images?: string[],
  legacyImage?: string,
): string[] => {
  if (Array.isArray(images)) {
    // NOTE: images array should not contain nullish values; but there were some null-images related errors, so there might be some corrupted records out there
    return images.filter(image => !!image);
  }
  if (legacyImage) {
    return [legacyImage];
  }
  return [];
};

export const getPresentations = (
  productId: string,
  presentations?: Product['presentations'],
): Product['presentations'] => {
  if (!presentations) {
    return null;
  }
  let updatedPresentations = presentations;

  // Check for legacy (deprecated) presentations
  if (Array.isArray(updatedPresentations)) {
    updatedPresentations = transformProductPresentationIntoProductOption(updatedPresentations);
  }
  updatedPresentations = prependOptionConfigurationWithProductId(productId, updatedPresentations);

  updatedPresentations.items.forEach(presentation => {
    // Maps legacy "stock" property
    presentation.stockQty =
      presentation.stockQty === undefined
        ? presentation['stock'] === false
          ? 0
          : null
        : presentation.stockQty;

    delete presentation['stock'];
  });

  return updatedPresentations;
};

export const prependOptionConfigurationWithProductId = (
  productId: string,
  option?: OptionConfiguration,
) => {
  if (!option) {
    return undefined;
  }
  if (!option.id.startsWith(`${productId}-`)) {
    option.id = `${productId}-${option.id}`;
  }
  option.items.forEach(item => {
    if (!item.id.startsWith(`${productId}-`)) {
      item.id = `${productId}-${item.id}`;
    }
  });

  return option;
};

export const transformProductPresentationIntoProductOption = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  presentations: any[],
): OptionConfiguration => {
  return {
    id: 'presentations',
    name: 'Presentaciones',
    rangeType: ProductOptionRangeType.FIXED,
    min: 1,
    displayItemQuantities: false,
    sortOrder: 0,
    items: presentations.map<ProductOptionItem>((presentation, index) => {
      const stockQty = presentation.stock === false ? 0 : null;

      return {
        id: `presentation-${index}`,
        ...presentation,
        stockQty,
        sortOrder: index,
      };
    }),
  };
};

// Maps legacy product options
export const getProductOptions = (
  productId: string,
  options: Product['options'],
): Product['options'] => {
  if (!Array.isArray(options)) {
    return [];
  }

  return filterInvalidProductOptions(options)
    .map(option => prependOptionConfigurationWithProductId(productId, option))
    .map((option, optionIndex) => ({
      ...option,
      sortOrder: optionIndex,
      min: option.rangeType === 'any' ? null : option.min,
      max: option.rangeType === 'any' ? null : option.max,
      items: option.items.map((item, itemIndex) => {
        // Maps legacy "stock" property
        const stockQty =
          item.stockQty === undefined ? (item['stock'] === false ? 0 : null) : item.stockQty;

        delete item['stock'];

        return {
          ...item,
          sortOrder: itemIndex,
          quantity: item.quantity || 0,
          stockQty,
        };
      }),
    }));
};

// Filters-out legacy/deprecated options
const filterInvalidProductOptions = (options?: OptionConfiguration[]): OptionConfiguration[] => {
  // For each option, if that option has at least one item of type 'string', then we filter the whole option away
  return options.filter(option => !option.items.some(item => typeof item === 'string'));
};

// Stock for product with presentations uses stock of every presentation
export const isProductWithoutStock = ({
  stockQty,
  presentationItems,
}: {
  stockQty: Product['stockQty'];
  presentationItems?: { stockQty: Product['stockQty'] }[];
}): boolean => {
  return Array.isArray(presentationItems)
    ? presentationItems.every(item => item.stockQty === 0) || false
    : stockQty === 0;
};

export const productHasLinkedOptions = (product: Pick<Product, 'linkedOptionIds'>): boolean => {
  return Array.isArray(product.linkedOptionIds) && product.linkedOptionIds.length > 0;
};

export const productHasLinkedProductTags = (
  product: Pick<Product, 'linkedProductTagIds'>,
): boolean => {
  return Array.isArray(product.linkedProductTagIds) && product.linkedProductTagIds.length > 0;
};

export function sortProductsByStock() {
  return (productA: Product, productB: Product) => {
    if (isProductWithoutStock(productA)) {
      return 1;
    }
    return isProductWithoutStock(productB) ? -1 : 0;
  };
}
