export type StockItemType = 'product' | 'presentation' | 'option-item' | 'unknown';
export type StockItem = {
  id: string;
  name: string;
  stockQty: number | null; // null is equal to "Infinite"; 0 is without stock; any number means finite stock
  type: StockItemType;
  parentItem?: ParentStockItem;
};
export type StockStatus = 'up-to-date' | 'unknown';
export type ParentStockItem = {
  id: string;
  name: string;
};

export class StockInfo {
  item: StockItem;
  totalStockInCart: number;
  status: StockStatus;

  private consumedStockByCartItemMap: Record<string, number>;

  constructor(item: StockItem, status: StockStatus = 'up-to-date') {
    this.item = item;
    this.status = status;
    this.totalStockInCart = 0;
    this.consumedStockByCartItemMap = {};
  }

  get hasUnlimitedStock(): boolean {
    return this.item.stockQty === undefined || this.item.stockQty === null;
  }

  get availableStock(): number {
    return this.hasUnlimitedStock ? Number.MAX_SAFE_INTEGER : this.item.stockQty || 0;
  }

  get hasStockAvailable(): boolean {
    return this.availableStock > 0;
  }

  get missingStock(): number {
    if (this.availableStock < this.totalStockInCart) {
      return this.totalStockInCart - this.availableStock;
    }
    return 0;
  }

  get hasMissingStock(): boolean {
    return this.missingStock > 0;
  }

  get isOutOfStock(): boolean {
    return this.availableStock === 0;
  }

  getMissingStockByCartItem(cartItemId: string): number {
    if (cartItemId) {
      if (this.availableStock < this.consumedStockByCartItemMap[cartItemId]) {
        return this.consumedStockByCartItemMap[cartItemId] - this.availableStock;
      }
    } else {
      if (this.availableStock < this.totalStockInCart) {
        return this.totalStockInCart - this.availableStock;
      }
    }
    return 0;
  }

  getConsumedStock(cartItemId?: string): number {
    if (!cartItemId) {
      return 0;
    }

    return this.consumedStockByCartItemMap[cartItemId] || 0;
  }

  setConsumedStock(consumedStock: number, cartItemId: string) {
    this.totalStockInCart += consumedStock;

    this.consumedStockByCartItemMap[cartItemId] = consumedStock;
  }

  processConsumedStockAndReturnNewInstance(): StockInfo {
    return new StockInfo({
      ...this.item,
      stockQty: this.hasUnlimitedStock
        ? undefined
        : (this.item.stockQty || 0) - this.totalStockInCart,
    });
  }
}
