import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="info"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconInfoComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5 7.0625C13.6988 7.0625 6.5625 14.1988 6.5625 23C6.5625 31.8012 13.6988 38.9375 22.5 38.9375C31.3012 38.9375 38.4375 31.8012 38.4375 23C38.4375 14.1988 31.3012 7.0625 22.5 7.0625ZM22.5 36.2338C15.1929 36.2338 9.26618 30.3071 9.26618 23C9.26618 15.6929 15.1929 9.76618 22.5 9.76618C29.8071 9.76618 35.7338 15.6929 35.7338 23C35.7338 30.3071 29.8071 36.2338 22.5 36.2338Z"
    />
    <path
      d="M20.625 17.2076C20.625 17.6605 20.8225 18.0948 21.1742 18.415C21.5258 18.7353 22.0027 18.9152 22.5 18.9152C22.9973 18.9152 23.4742 18.7353 23.8258 18.415C24.1775 18.0948 24.375 17.6605 24.375 17.2076C24.375 16.7547 24.1775 16.3204 23.8258 16.0001C23.4742 15.6799 22.9973 15.5 22.5 15.5C22.0027 15.5 21.5258 15.6799 21.1742 16.0001C20.8225 16.3204 20.625 16.7547 20.625 17.2076ZM23.4375 21.192H21.5625C21.3906 21.192 21.25 21.32 21.25 21.4766V31.1529C21.25 31.3094 21.3906 31.4375 21.5625 31.4375H23.4375C23.6094 31.4375 23.75 31.3094 23.75 31.1529V21.4766C23.75 21.32 23.6094 21.192 23.4375 21.192Z"
    />
  </svg>`,
})
export class IconInfoComponent extends IconComponent {}
