import { DEFAULT_LOCALE_ID } from "./getDefaultLanguage";
import { CurrencyCode, CurrencyLocaleData, LocaleID } from "./types";

export const getCurrencyLocaleDataById = (localeId: LocaleID): CurrencyLocaleData => {
  return currencyLocaleDataById[localeId] || currencyLocaleDataById[DEFAULT_LOCALE_ID];
}

export const getCurrencyLocaleDataByCountryCode = (currencyCode = ''): CurrencyLocaleData => {
  return currencyLocaleDataByCode[currencyCode] || currencyLocaleDataById[DEFAULT_LOCALE_ID];
}

/*
Source:
console.log(new Intl.NumberFormat(localeId, { style: 'currency', currency: currencyCode }).format(123456.789));
Output:
> es-AR: "$ 123.456,79"
> es-CL: "$123.457"
> es-MX: "$123,456.79"
> es-PE: "S/ 123,456.79"
> es-UY: "$ 123.456,79"
> es-CO: "$ 123.456,79"
> es-419: "$ 123.456,79"
> pt: "R$ 123.456,79"

NOTE1: for the moment we are ignoring the recommended symbol separation
NOTE2: for excel, currency format uses the thousand and decimal separators of the OS, check: https://github.com/exceljs/exceljs#styles 
*/
const currencyLocaleDataByCode: Record<CurrencyCode, CurrencyLocaleData> = {
  'ARS': {
    currencyCode: 'ARS',
    currencySymbol: '$',
    decimalsLength: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    excelNumberFormat: '"$"#,##0.00',
  },
  'BOB': {
    currencyCode: 'BOB',
    currencySymbol: 'Bs',
    decimalsLength: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    excelNumberFormat: '"Bs"#,##0.00',
  },
  'BRL': {
    currencyCode: 'BRL',
    currencySymbol: 'R$',
    decimalsLength: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    excelNumberFormat: '"R$"#,##0.00',
  },
  'BSD': {
    currencyCode: 'BSD',
    currencySymbol: 'BSD',
    decimalsLength: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    excelNumberFormat: '"BSD"#,##0.00',
  },
  'CAD': {
    currencyCode: 'CAD',
    currencySymbol: '$',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"$"#,##0.00',
  },
  'CLP': {
    currencyCode: 'CLP',
    currencySymbol: '$',
    decimalsLength: 0,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    excelNumberFormat: '"$"#,##0',
  },
  'COP': {
    currencyCode: 'COP',
    currencySymbol: '$',
    decimalsLength: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    excelNumberFormat: '"$"#.##0,00',
  },
  'CRC': {
    currencyCode: 'CRC',
    currencySymbol: '₡',
    decimalsLength: 2,
    thousandsSeparator: ' ',
    decimalSeparator: ',',
    excelNumberFormat: '"₡"#,##0.00',
  },
  'CUP': {
    currencyCode: 'CUP',
    currencySymbol: '$',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"$"#,##0.00',
  },
  'DOP': {
    currencyCode: 'DOP',
    currencySymbol: 'RD$',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"RD$"#,##0.00',
  },
  'EUR': {
    currencyCode: 'EUR',
    currencySymbol: '€',
    decimalsLength: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    excelNumberFormat: '"€"#,##0.00',
  },
  'GTQ': {
    currencyCode: 'GTQ',
    currencySymbol: 'Q',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"Q"#,##0.00',
  },
  'GYD': {
    currencyCode: 'GYD',
    currencySymbol: '$',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"$"#,##0.00',
  },
  'HNL': {
    currencyCode: 'HNL',
    currencySymbol: 'L',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"L"#,##0.00',
  },
  'JMD': {
    currencyCode: 'JMD',
    currencySymbol: '$',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"$"#,##0.00',
  },
  'MXN': {
    currencyCode: 'MXN',
    currencySymbol: '$',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"$"#,##0.00',
  },
  'NIO': {
    currencyCode: 'NIO',
    currencySymbol: 'C$',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"C$"#,##0.00',
  },
  'PAB': {
    currencyCode: 'PAB',
    currencySymbol: 'B/.',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"B/."#,##0.00',
  },
  'PEN': {
    currencyCode: 'PEN',
    currencySymbol: 'S/',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"S/"#,##0.00',
  },
  'PYG': {
    currencyCode: 'PYG',
    currencySymbol: 'Gs.',
    decimalsLength: 0,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    excelNumberFormat: '"Gs."#,##0',
  },
  'USD': {
    currencyCode: 'USD',
    currencySymbol: '$',
    decimalsLength: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    excelNumberFormat: '"$"#,##0.00',
  },
  'UYU': {
    currencyCode: 'UYU',
    currencySymbol: '$',
    decimalsLength: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    excelNumberFormat: '"$"#,##0.00',
  },
  'VES': {
    currencyCode: 'VES',
    currencySymbol: 'Bs.S',
    decimalsLength: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    excelNumberFormat: '"Bs.S"#,##0.00',
  },
};

const currencyLocaleDataById: Record<LocaleID, CurrencyLocaleData> = {
  'es-AR': currencyLocaleDataByCode.ARS,
  'es-CL': currencyLocaleDataByCode.CLP,
  'es-MX': currencyLocaleDataByCode.MXN,
  'es-PE': currencyLocaleDataByCode.PEN,
  'es-UY': currencyLocaleDataByCode.UYU,
  'es-CO': currencyLocaleDataByCode.COP,
  'es-419': currencyLocaleDataByCode.ARS,
  'pt': currencyLocaleDataByCode.BRL,
  'es': currencyLocaleDataByCode.EUR,
  'en-US': currencyLocaleDataByCode.USD,
};
