import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="check"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconCheckComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.7749 9.83418C35.3905 9.25269 36.2097 8.93121 37.0597 8.93759C37.9096 8.94397 38.7239 9.27771 39.3305 9.86838C39.9372 10.4591 40.2889 11.2605 40.3114 12.1035C40.3338 12.9465 40.0253 13.7652 39.451 14.3869L22.014 36.0232C21.7141 36.3436 21.3523 36.6007 20.95 36.7792C20.5477 36.9577 20.1132 37.0538 19.6726 37.0619C19.232 37.07 18.7943 36.9899 18.3857 36.8263C17.977 36.6627 17.6058 36.4191 17.2942 36.1099L5.7307 24.637C5.40867 24.3393 5.15039 23.9803 4.97124 23.5814C4.7921 23.1825 4.69577 22.7519 4.68801 22.3152C4.68025 21.8786 4.7612 21.4449 4.92605 21.04C5.0909 20.635 5.33626 20.2672 5.6475 19.9584C5.95873 19.6496 6.32947 19.4062 6.7376 19.2426C7.14572 19.079 7.58286 18.9987 8.02295 19.0064C8.46304 19.0141 8.89706 19.1097 9.29911 19.2874C9.70116 19.4652 10.063 19.7214 10.3631 20.0409L19.5142 29.116L34.6918 9.92957C34.7192 9.89619 34.7483 9.86434 34.7792 9.83418H34.7749Z"
      />
    </svg>
  `,
})
export class IconCheckComponent extends IconComponent {}
