import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="close"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconCloseComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.23124 7.73124C7.44269 7.51926 7.69388 7.35108 7.97043 7.23632C8.24698 7.12157 8.54345 7.0625 8.84286 7.0625C9.14227 7.0625 9.43874 7.12157 9.71529 7.23632C9.99183 7.35108 10.243 7.51926 10.4545 7.73124L22.5006 19.7819L34.5467 7.73124C34.7584 7.5196 35.0096 7.35172 35.2861 7.23718C35.5627 7.12264 35.859 7.06369 36.1583 7.06369C36.4576 7.06369 36.754 7.12264 37.0305 7.23718C37.3071 7.35172 37.5583 7.5196 37.77 7.73124C37.9816 7.94288 38.1495 8.19414 38.264 8.47066C38.3786 8.74718 38.4375 9.04355 38.4375 9.34286C38.4375 9.64216 38.3786 9.93854 38.264 10.2151C38.1495 10.4916 37.9816 10.7428 37.77 10.9545L25.7193 23.0006L37.77 35.0467C37.9816 35.2584 38.1495 35.5096 38.264 35.7861C38.3786 36.0627 38.4375 36.359 38.4375 36.6583C38.4375 36.9576 38.3786 37.254 38.264 37.5305C38.1495 37.8071 37.9816 38.0583 37.77 38.27C37.5583 38.4816 37.3071 38.6495 37.0305 38.764C36.754 38.8786 36.4576 38.9375 36.1583 38.9375C35.859 38.9375 35.5627 38.8786 35.2861 38.764C35.0096 38.6495 34.7584 38.4816 34.5467 38.27L22.5006 26.2193L10.4545 38.27C10.2428 38.4816 9.99158 38.6495 9.71506 38.764C9.43854 38.8786 9.14216 38.9375 8.84286 38.9375C8.54355 38.9375 8.24718 38.8786 7.97066 38.764C7.69414 38.6495 7.44288 38.4816 7.23124 38.27C7.0196 38.0583 6.85172 37.8071 6.73718 37.5305C6.62264 37.254 6.56369 36.9576 6.56369 36.6583C6.56369 36.359 6.62264 36.0627 6.73718 35.7861C6.85172 35.5096 7.0196 35.2584 7.23124 35.0467L19.2819 23.0006L7.23124 10.9545C7.01926 10.743 6.85108 10.4918 6.73632 10.2153C6.62157 9.93874 6.5625 9.64227 6.5625 9.34286C6.5625 9.04345 6.62157 8.74698 6.73632 8.47043C6.85108 8.19388 7.01926 7.94269 7.23124 7.73124Z"
      />
    </svg>
  `,
})
export class IconCloseComponent extends IconComponent {}
