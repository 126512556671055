import {
  Component,
  Input,
  HostBinding,
  ChangeDetectionStrategy,
  ContentChild,
  TemplateRef,
  AfterContentInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  IconAlertComponent,
  IconCheckComponent,
  IconCloseComponent,
  IconComponent,
  IconInfoComponent,
  IconSize,
} from '@pedix-workspace/angular-ui-icons';
import clsx from 'clsx';

import { NgTemplateOutlet } from '@angular/common';

export const alertTypes = ['danger', 'warning', 'info', 'success', 'neutral'] as const;
export const alertDisplays = ['block', 'inline', 'inline-block'] as const;
export const alertAppareances = ['solid', 'bare'] as const;
export const alertSizes = ['sm', 'md'] as const;

export type AlertTypes = typeof alertTypes;
export type AlertType = AlertTypes[number];

export type AlertDisplays = typeof alertDisplays;
export type AlertDisplay = AlertDisplays[number];

export type AlertAppareances = typeof alertAppareances;
export type AlertAppareance = AlertAppareances[number];

export type AlertSizes = typeof alertSizes;
export type AlertSize = AlertSizes[number];

@Component({
  selector: 'pxw-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    IconInfoComponent,
    IconCheckComponent,
    IconAlertComponent,
    IconCloseComponent,
    NgTemplateOutlet,
  ],
})
export class AlertComponent implements AfterContentInit {
  @ContentChild('alertDetails') alertDetails: TemplateRef<unknown> | null = null;
  @ContentChild('alertValidation') alertValidation: TemplateRef<unknown> | null = null;
  @ContentChild(IconComponent) customIcon: IconComponent;

  @Input() type: AlertType = 'info';
  @Input() display: AlertDisplay = 'block';
  @Input() size: AlertSize = 'md';
  @Input() appareance: AlertAppareance = 'solid';
  @Input() withoutIcon: boolean;
  @Input() rounded = true;
  @Input() displayCloseButton = false;

  @Output() closeAlert = new EventEmitter();

  @HostBinding('class') get classes() {
    return clsx(
      `type-${this.type}`,
      `display-${this.display}`,
      `size-${this.size}`,
      `appareance-${this.appareance}`,
      {
        rounded: this.rounded,
        'with-details': Boolean(this.alertDetails),
        'without-icon': this.withoutIcon,
      },
    );
  }

  get iconSize(): IconSize {
    if (this.display == 'block') {
      return this.size === 'md' ? 'lg' : 'md';
    }
    return this.size;
  }

  ngAfterContentInit(): void {
    if (this.customIcon) {
      this.customIcon.size = this.iconSize;
    }
  }
}
