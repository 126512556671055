import { DocumentData, FirestoreDataConverter } from 'firebase-admin/firestore';
import { FirestoreDataConverter as FirestoreDataConverter2, SetOptions } from "firebase/firestore";

export type FirestoreNestedConverter<T> = {
  toFirestore?: (model: T | DocumentData) => T;
  fromFirestore?: (model: T) => T;
}

export type FirestoreConverter<T> = FirestoreDataConverter<T> & FirestoreDataConverter2<T>

export const withNestedConverter = <T>(firestoreConverter: FirestoreConverter<T>) => {
  return (nestedConverter: FirestoreNestedConverter<T> = {}): FirestoreConverter<T> => ({
    toFirestore(documentData: T, options?: SetOptions) {
      const convertedData = firestoreConverter.toFirestore(documentData, options);

      if (nestedConverter.toFirestore) {
        return nestedConverter.toFirestore(convertedData);
      }
  
      return convertedData;
    },
    fromFirestore(snapshot) {
      const convertedData = firestoreConverter.fromFirestore(snapshot);

      if (nestedConverter.fromFirestore) {
        return nestedConverter.fromFirestore(convertedData);
      }
  
      return convertedData;
    },
  })
};