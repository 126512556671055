import { ProductOptionItem } from '@pedix-workspace/utils';
import { StockItem } from './stock-info.model';
import { StockProduct } from './stock-map.model';

export function unkownStockItem(id: string): StockItem {
  return { id, name: '-', stockQty: undefined, type: 'unknown' };
}

export function productToStockItem(product: StockProduct): StockItem {
  return {
    id: product.id,
    name: product.name,
    stockQty: product.hidden === true || product.active === false ? 0 : product.stockQty,
    type: 'product',
  };
}

export function presentationToStockItem(
  presentation: ProductOptionItem,
  product: StockProduct,
): StockItem {
  return {
    id: presentation.id,
    name: presentation.name,
    stockQty: product.hidden === true || product.active === false ? 0 : presentation.stockQty,
    type: 'presentation',
    parentItem: product,
  };
}

export function optionToStockItem(option: ProductOptionItem, product: StockProduct): StockItem {
  return {
    id: option.id,
    name: option.name,
    stockQty: option.stockQty,
    type: 'option-item',
    parentItem: product,
  };
}
