import { Injectable, signal } from '@angular/core';
import { IconSize } from './icon/icon.component';

@Injectable()
export class IconContextService {
  #size = signal<IconSize | null>(null);

  get size() {
    return this.#size();
  }

  set size(size: IconSize | null) {
    this.#size.set(size);
  }

  set parentSize(size: IconSize | null) {
    if (size === 'lg') {
      this.#size.set('md');
    } else if (size === 'md') {
      this.#size.set('sm');
    } else if (size === 'sm') {
      this.#size.set('xs');
    } else {
      this.#size.set('xs');
    }
  }
}
