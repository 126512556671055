import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { StockMap, StockProduct } from '@pedix-workspace/shared-stock';
import { CartItem, Product } from '@pedix-workspace/utils';

@Injectable({ providedIn: 'root' })
export class StockService {
  stock$: Observable<StockMap>;
  #cartItemOverride$ = new BehaviorSubject<CartItem | undefined>(undefined);
  products$ = new BehaviorSubject<StockProduct[]>([]);
  cartItems$ = new BehaviorSubject<CartItem[]>([]);

  set cartItemOverride(cartItemOverride: CartItem) {
    this.#cartItemOverride$.next(cartItemOverride);
  }

  constructor() {
    this.stock$ = combineLatest([this.products$, this.cartItems$, this.#cartItemOverride$]).pipe(
      map(([products, cartItems, cartItemOverride]) => {
        const cartItemsCopy = [...cartItems];

        if (cartItemOverride) {
          const index = cartItemsCopy.findIndex(cartItem => cartItem.id === cartItemOverride.id);

          if (index !== -1) {
            cartItemsCopy.splice(index, 1, cartItemOverride);
          } else {
            cartItemsCopy.push(cartItemOverride);
          }
        }
        return new StockMap(products, cartItemsCopy);
      }),
      shareReplay(1),
    );
  }

  isProductOutOfStock$(product: Pick<Product, 'id' | 'presentations'>) {
    return this.stock$.pipe(
      map(stock => {
        if (product.presentations) {
          return (
            product.presentations?.items?.every(
              item => stock.getStockInfoByItem(item.id).isOutOfStock,
            ) || false
          );
        } else {
          return stock.getStockInfoByItem(product.id).isOutOfStock;
        }
      }),
    );
  }
}
