import { FirestoreTimestamp } from "./types";

export const firestoreDateParser = (date: Date | FirestoreTimestamp | string | number | null | undefined): Date | undefined => {
  if (!date) {
    return undefined;
  }
  if (date instanceof Date) {
    return date;
  }
  if (typeof date === 'string') {
    return new Date(date);
  }
  if (typeof date === 'number') {
    return new Date(date);
  }
  if (isFirestoreTimestamp(date)) {
    if (typeof date.toDate === 'function') {
      return date.toDate();
    }
    if (date.seconds) {
      return new Date(date.seconds * 1000);
    }
  }
  return undefined;
};

export function isFirestoreTimestamp(value: any): value is FirestoreTimestamp {
  return typeof value?.toDate === 'function' || typeof value?.seconds === 'number';
}
