import { Establishment, LocaleID, SUPPORTED_LANGUAGES } from './types';

export const DEFAULT_LOCALE_ID: LocaleID = 'es-419';

export const getDefaultLanguage = (countryCode?: Establishment['countryCode'], selectedLanguage?: SUPPORTED_LANGUAGES) => {
  if (selectedLanguage === 'en') {
    return 'enUs';
  }
  if (!countryCode) {
    return 'esMx';
  }
  return ['AR', 'UY'].includes(countryCode) ? 'esAr' : 'esMx';
};

export const getTemplateLanguage = (countryCode?: Establishment['countryCode']) => {
  if (!countryCode) {
    return 'es_MX';
  }
  return ['AR', 'UY'].includes(countryCode) ? 'es_AR' : 'es_MX';
};

// Gets default locale (ISO code used by angular for LOCALE_ID token) for a given countryCode
export const getDefaultLocaleId = (countryCode?: Establishment['countryCode'], selectedLanguage?: SUPPORTED_LANGUAGES): LocaleID => {
  if (selectedLanguage === 'en') {
    return 'en-US';
  }
  if (countryCode === 'BR') {
    return 'pt'
  }
  if (countryCode === 'ES') {
    return 'es'
  }
  if (countryCode === 'XX') {
    return DEFAULT_LOCALE_ID;
  }
  if (['AR', 'CL', 'MX', 'PE', 'UY', 'CO'].includes(countryCode)) {
    return `es-${countryCode}`;
  }
  return DEFAULT_LOCALE_ID;
};
