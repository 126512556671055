import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="loading"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconLoadingComponent }],
  template: `<svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.0002 34.6667C24.5323 34.6667 26.0493 34.3649 27.4648 33.7786C28.8803 33.1923 30.1664 32.3329 31.2497 31.2496C32.3331 30.1662 33.1925 28.8801 33.7788 27.4647C34.3651 26.0492 34.6668 24.5321 34.6668 23C34.6668 21.4679 34.3651 19.9508 33.7788 18.5354C33.1925 17.1199 32.3331 15.8338 31.2497 14.7504C30.1664 13.6671 28.8803 12.8077 27.4648 12.2214C26.0493 11.6351 24.5323 11.3333 23.0002 11.3333C19.906 11.3333 16.9385 12.5625 14.7506 14.7504C12.5627 16.9384 11.3335 19.9058 11.3335 23C11.3335 26.0942 12.5627 29.0617 14.7506 31.2496C16.9385 33.4375 19.906 34.6667 23.0002 34.6667ZM23.0002 39.6667C32.2052 39.6667 39.6668 32.205 39.6668 23C39.6668 13.795 32.2052 6.33334 23.0002 6.33334C13.7952 6.33334 6.3335 13.795 6.3335 23C6.3335 32.205 13.7952 39.6667 23.0002 39.6667Z"
    />
    <path
      d="M6.3335 23C6.3335 13.795 13.7952 6.33334 23.0002 6.33334V11.3333C19.906 11.3333 16.9385 12.5625 14.7506 14.7504C12.5627 16.9384 11.3335 19.9058 11.3335 23H6.3335Z"
    />
  </svg>`,
})
export class IconLoadingComponent extends IconComponent {}
