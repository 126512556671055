import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'pxw-icon[icon="alert"]',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../icon/icon.component.scss'],
  providers: [{ provide: IconComponent, useExisting: IconAlertComponent }],
  template: `
    <svg viewBox="0 0 45 46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.6789 32.2089L20.3338 11.2561C21.2696 9.41464 23.7332 9.41464 24.6677 11.2561L35.3213 32.2089C36.2151 33.9729 35.026 36.1254 33.1517 36.1254H11.8458C9.9729 36.1254 8.78246 33.9729 9.68021 32.2089H9.6789Z"
        stroke="#FF7C7C"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.375 30.6674C24.375 30.2145 24.1775 29.7802 23.8258 29.46C23.4742 29.1397 22.9973 28.9598 22.5 28.9598C22.0027 28.9598 21.5258 29.1397 21.1742 29.46C20.8225 29.7802 20.625 30.2145 20.625 30.6674C20.625 31.1203 20.8225 31.5546 21.1742 31.8749C21.5258 32.1951 22.0027 32.375 22.5 32.375C22.9973 32.375 23.4742 32.1951 23.8258 31.8749C24.1775 31.5546 24.375 31.1203 24.375 30.6674ZM21.5625 26.683L23.4375 26.683C23.6094 26.683 23.75 26.555 23.75 26.3984L23.75 16.7221C23.75 16.5656 23.6094 16.4375 23.4375 16.4375L21.5625 16.4375C21.3906 16.4375 21.25 16.5656 21.25 16.7221L21.25 26.3984C21.25 26.555 21.3906 26.683 21.5625 26.683Z"
      />
    </svg>
  `,
})
export class IconAlertComponent extends IconComponent {}
